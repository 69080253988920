<template>
    <v-container fluid class="mx-0 px-3">
        <v-card flat class="avsb-card-background">
            <v-card-title class="pl-1 py-2">Reports</v-card-title>
        </v-card>

        <v-card elevation="0" outlined>
            <v-card-text>
                <v-row dense>
                    <v-col>
                        <ValidationObserver ref="observer" v-slot="{}">
                            <v-form
                                @submit.prevent="
                                    retrieveReport(
                                        table.options,
                                        retrievePayload.customer_id,
                                        range_start,
                                        range_end
                                    )
                                "
                            >
                                <v-row dense align="center">
                                    <v-col cols="12" sm="2">
                                        <CustomLabel
                                            for="range_start"
                                            label="From Range"
                                            required
                                        />
                                        <CustomTextField
                                            id="range_start"
                                            inputIcon="mdi-calendar"
                                            inputPlaceholder="YYYY-MM-DD"
                                            :inputValue="range_start"
                                            validationName="range_start"
                                            validationRules="required"
                                            @change="
                                                updateLocalForm(
                                                    $event,
                                                    'range_start'
                                                )
                                            "
                                            @click:prepend-inner="
                                                showIconDialog
                                            "
                                            @input="
                                                updateLocalForm(
                                                    $event,
                                                    'range_start'
                                                )
                                            "
                                        />
                                        <!-- <CustomDatePickerField
                                            id="range_start"
                                            inputIcon="mdi-calendar"
                                            inputPlaceholder="YYYY-MM-DD"
                                            :inputValue="range_start"
                                            validationName="range_start"
                                            validationRules="required"
                                            @change="
                                                updateLocalForm(
                                                    $event,
                                                    'range_start'
                                                )
                                            "
                                            @input="
                                                updateLocalForm(
                                                    $event,
                                                    'range_start'
                                                )
                                            "
                                        /> -->
                                    </v-col>

                                    <v-col cols="12" sm="2">
                                        <CustomLabel
                                            for="range_end"
                                            label="To Range"
                                            required
                                        />
                                        <CustomTextField
                                            id="range_end"
                                            inputIcon="mdi-calendar"
                                            inputPlaceholder="YYYY-MM-DD"
                                            :inputValue="range_end"
                                            validationName="range_end"
                                            validationRules="required|beyondStartDate:@range_start"
                                            @change="
                                                updateLocalForm(
                                                    $event,
                                                    'range_end'
                                                )
                                            "
                                            @click:prepend-inner="
                                                showIconDialog
                                            "
                                            @input="
                                                updateLocalForm(
                                                    $event,
                                                    'range_end'
                                                )
                                            "
                                        />
                                        <!-- <CustomDatePickerField
                                            id="range_end"
                                            inputIcon="mdi-calendar"
                                            inputPlaceholder="YYYY-MM-DD"
                                            :inputValue="range_end"
                                            validationName="range_end"
                                            validationRules="required|beyondStartDate:@range_start"
                                            @change="
                                                updateLocalForm(
                                                    $event,
                                                    'range_end'
                                                )
                                            "
                                            @input="
                                                updateLocalForm(
                                                    $event,
                                                    'range_end'
                                                )
                                            "
                                        /> -->
                                    </v-col>

                                    <v-col cols="12" sm="2">
                                        <CustomLabel
                                            for="customer"
                                            label="Customer"
                                        />
                                        <CustomAutoCompleteField
                                            id="customer"
                                            inputIsClearable
                                            inputPlaceholder="Customer"
                                            :optionItems="customers"
                                            optionText="name"
                                            optionValue="ids"
                                            :search-input.sync="
                                                searchCustomerOps
                                            "
                                            @change="
                                                handleAutoCompleteChange(
                                                    'retrievePayload',
                                                    'customer_id',
                                                    'id',
                                                    $event
                                                )
                                            "
                                            @click:clear="
                                                handleAutoCompleteChange(
                                                    'retrievePayload',
                                                    'customer_id',
                                                    'id',
                                                    $event
                                                )
                                            "
                                            @intersect="
                                                retrieveCustomers(
                                                    'name',
                                                    retrieveCustomerOps
                                                )
                                            "
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="2">
                                        <CustomButton
                                            :block="
                                                $vuetify.breakpoint.xs
                                                    ? true
                                                    : false
                                            "
                                            btnColour="primary"
                                            :btnIsLoading="
                                                retrieveOps.isLoading
                                            "
                                            btnLabel="Search"
                                            tooltipMsg="Create Report"
                                            type="submit"
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="2">
                                        <CustomSelectField
                                            id="exclusions"
                                            inputMultiple
                                            inputPlaceholder="Exclusion Fields"
                                            :optionItems="exportedAttributes"
                                            optionText="name"
                                            optionValue="id"
                                            @change="
                                                handleSelectChange(
                                                    $event,
                                                    'exclusions'
                                                )
                                            "
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <CustomButton
                                            :block="
                                                $vuetify.breakpoint.xs
                                                    ? true
                                                    : false
                                            "
                                            :btnIsLoading="
                                                downloadOps.isLoading
                                            "
                                            btnLabel="Download"
                                            tooltipMsg="Download Report"
                                            @click="
                                                downloadReport(
                                                    table.options,
                                                    retrievePayload.customer_id,
                                                    range_start,
                                                    range_end,
                                                    exclusions
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </ValidationObserver>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col>
                        <v-data-table
                            :footer-props="table.footers"
                            :headers="table.headers"
                            :items="items"
                            :loading="retrieveOps.isLoading"
                            loading-text="Loading..."
                            no-data-text="No data so far."
                            no-results-text="No matches found."
                            :options.sync="table.options"
                            :search="table.search"
                            :server-items-length="retrieveOps.serverItemsLength"
                            show-select
                            v-model="table.selectedItems"
                            @update:items-per-page="
                                retrieveReport(
                                    table.options,
                                    retrievePayload.customer_id,
                                    range_start,
                                    range_end
                                )
                            "
                            @update:page="
                                retrieveReport(
                                    table.options,
                                    retrievePayload.customer_id,
                                    range_start,
                                    range_end
                                )
                            "
                            @update:sort-by="
                                retrieveReport(
                                    table.options,
                                    retrievePayload.customer_id,
                                    range_start,
                                    range_end
                                )
                            "
                            @update:sort-desc="
                                retrieveReport(
                                    table.options,
                                    retrievePayload.customer_id,
                                    range_start,
                                    range_end
                                )
                            "
                        >
                            <!-- Helper methods -->
                            <template v-slot:item.range_start="{ item }">
                                <span>{{
                                    toLocalDateTime(item.range_start)
                                }}</span>
                            </template>

                            <template v-slot:item.range_end="{ item }">
                                <span>{{
                                    toLocalDateTime(item.range_end)
                                }}</span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { toLocalDateTime, isBulkSelected } from "@/services/helpers";
import { exportedAttributes } from "@/services/OptionMappers";

extend("beyondStartDate", {
    params: ["startDate"],
    validate(value, { startDate }) {
        return DateTime.fromSQL(value) >= DateTime.fromSQL(startDate);
    },
    message: "End date cannot be earlier than start date.",
});

export default {
    computed: {
        ...mapState("reportsUserMgmt", [
            "reports",
            "retrieveOps",
            "items",
            "downloadOps",
            "retrievePayload",
        ]),
        ...mapState("customersUserMgmt", [
            "customers",
            "retrieveCustomerOps",
            "searchCustomerOps",
        ]),
    },
    data: () => ({
        exportedAttributes: exportedAttributes,
        table: {
            footers: {
                "items-per-page-options": [20, 50, 100],
            },
            headers: [
                {
                    sortable: false,
                    text: "Customer",
                    value: "customer.abbreviation",
                },
                {
                    sortable: false,
                    text: "Start Date",
                    value: "start_date",
                },
                {
                    sortable: false,
                    text: "Start Time",
                    value: "start_time",
                },
                {
                    sortable: false,
                    text: "End Date",
                    value: "end_date",
                },
                {
                    sortable: false,
                    text: "End Time",
                    value: "end_time",
                },
                { sortable: false, text: "Category", value: "category.name" },
                { sortable: false, text: "Client", value: "client.name" },
                { sortable: false, text: "Task", value: "task.name" },
                {
                    align: "end",
                    sortable: false,
                    text: "Total Time",
                    value: "total_time",
                },
                {
                    align: "end",
                    sortable: false,
                    text: "Total Hours",
                    value: "total_hours",
                },
                {
                    align: "center",
                    sortable: false,
                    text: "Billable",
                    value: "billable",
                    width: 100,
                },
            ],
            search: "",
            selectedItems: [],
            options: {},
        },
        validated: false,
        range_start: null,
        range_end: null,
        // range_start: "2022-01-28",
        // range_end: "2022-02-28",
        exclusions: [],
        // range_start: null,
        // range_end: null,
    }),
    methods: {
        toLocalDateTime,
        isBulkSelected,
        sayHi(event) {
            console.log(event);
        },
        clearItem() {
            this.handleAutoCompleteChange(
                "retrievePayload",
                "customer_id",
                "id",
                null
            );
        },
        async downloadReport(
            options,
            customer_id,
            range_start,
            range_end,
            exclusions
        ) {
            let valid = await this.$refs.observer.validate();

            if (valid) {
                let params = {
                    page: options.page,
                    page_size: options.itemsPerPage,
                    order_by: options.sortBy[0],
                    order_desc: options.sortDesc[0],
                    range_start: range_start,
                    range_end: range_end,
                    customer_id: customer_id,
                    exclusions: exclusions,
                };
                this.$store.dispatch("reportsUserMgmt/downloadItem", params);
            }
        },
        handleAutoCompleteChange(form, name, property, item) {
            let payload = {
                form: form,
                name: name,
                property: property,
                item: item,
            };
            this.$store.commit("reportsUserMgmt/UPDATE_ITEM_FORM", {
                payload,
            });
        },
        handleSelectChange(event, form) {
            this[form] = [];
            event.forEach((item) => {
                this[form].push(item);
            });
        },
        rememberDateRanges(form, value) {
            if (form == "range_start") {
                localStorage.setItem("reportStartRange", value);
            }

            if (form == "range_end") {
                localStorage.setItem("reportEndRange", value);
            }
        },
        rePopulateDateRangeFields() {
            if (localStorage.getItem("reportStartRange") != null) {
                this.range_start = localStorage.getItem("reportStartRange");
            }

            if (localStorage.getItem("reportEndRange") != null) {
                this.range_end = localStorage.getItem("reportEndRange");
            }
        },
        retrieveCustomers(order_by, meta) {
            this.$store.dispatch("customersUserMgmt/retrieveItems", {
                order_by,
                ...meta,
            });
        },
        async retrieveReport(options, customer_id, range_start, range_end) {
            let valid = await this.$refs.observer.validate();

            if (!valid) {
                return;
            }

            this.$store.dispatch("reportsUserMgmt/retrieveReport", {
                page: options.page,
                page_size: options.itemsPerPage,
                order_by: options.sortBy[0],
                order_desc: options.sortDesc[0],
                range_start: range_start,
                range_end: range_end,
                customer_id: customer_id,
            });
        },
        searchCustomers(term, order_by, meta) {
            let options = { term, order_by, ...meta };
            this.$store.dispatch("customersUserMgmt/searchItem", options);
        },
        showDeleteDialog(item) {
            let payload = { form: "deletePayload", item: item };
            this.$store.commit("customersAdminMgmt/POPULATE_ITEM", payload);
            this.$store.commit("customersAdminMgmt/SHOW_DELETE_OPS_DIALOG");
        },
        showDisableDialog(item) {
            let payload = { form: "disablePayload", item: item };
            this.$store.commit("customersAdminMgmt/POPULATE_ITEM", payload);
            this.$store.commit("customersAdminMgmt/SHOW_DISABLE_OPS_DIALOG");
        },
        showEnableDialog(item) {
            let payload = { form: "enablePayload", item: item };
            this.$store.commit("customersAdminMgmt/POPULATE_ITEM", payload);
            this.$store.commit("customersAdminMgmt/SHOW_ENABLE_OPS_DIALOG");
        },
        showIconDialog(event) {
            console.log(event);
            console.log("Show icon dialog.");
        },
        updateLocalForm(event, form) {
            if (event instanceof InputEvent) {
                this[form] = event.target.value;
                this.rememberDateRanges(form, event.target.value);
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this[form] = event;
                this.rememberDateRanges(form, event);
            }
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("reportsUserMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("reportsUserMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
        viewItem(record, item) {
            let payload = { form: "customer", item: item.item };
            this.$store.commit("customersAdminMgmt/POPULATE_ITEM", payload);
            this.$store.commit("customersAdminMgmt/SHOW_VIEW_OPS_DIALOG");
        },
    },
    name: "Report",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {
        this.rePopulateDateRangeFields();
    },
    beforeMount() {
        this.retrieveCustomers("name", this.retrieveCustomerOps);
    },
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {
        this.$store.commit("reportsUserMgmt/FLUSH_ITEMS");
    },
};
</script>

