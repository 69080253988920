var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mx-0 px-3",attrs:{"fluid":""}},[_c('v-card',{staticClass:"avsb-card-background",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pl-1 py-2"},[_vm._v("Reports")])],1),_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.retrieveReport(
                                    _vm.table.options,
                                    _vm.retrievePayload.customer_id,
                                    _vm.range_start,
                                    _vm.range_end
                                )}}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('CustomLabel',{attrs:{"for":"range_start","label":"From Range","required":""}}),_c('CustomTextField',{attrs:{"id":"range_start","inputIcon":"mdi-calendar","inputPlaceholder":"YYYY-MM-DD","inputValue":_vm.range_start,"validationName":"range_start","validationRules":"required"},on:{"change":function($event){return _vm.updateLocalForm(
                                                $event,
                                                'range_start'
                                            )},"click:prepend-inner":_vm.showIconDialog,"input":function($event){return _vm.updateLocalForm(
                                                $event,
                                                'range_start'
                                            )}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('CustomLabel',{attrs:{"for":"range_end","label":"To Range","required":""}}),_c('CustomTextField',{attrs:{"id":"range_end","inputIcon":"mdi-calendar","inputPlaceholder":"YYYY-MM-DD","inputValue":_vm.range_end,"validationName":"range_end","validationRules":"required|beyondStartDate:@range_start"},on:{"change":function($event){return _vm.updateLocalForm(
                                                $event,
                                                'range_end'
                                            )},"click:prepend-inner":_vm.showIconDialog,"input":function($event){return _vm.updateLocalForm(
                                                $event,
                                                'range_end'
                                            )}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('CustomLabel',{attrs:{"for":"customer","label":"Customer"}}),_c('CustomAutoCompleteField',{attrs:{"id":"customer","inputIsClearable":"","inputPlaceholder":"Customer","optionItems":_vm.customers,"optionText":"name","optionValue":"ids","search-input":_vm.searchCustomerOps},on:{"update:searchInput":function($event){_vm.searchCustomerOps=$event},"update:search-input":function($event){_vm.searchCustomerOps=$event},"change":function($event){return _vm.handleAutoCompleteChange(
                                                'retrievePayload',
                                                'customer_id',
                                                'id',
                                                $event
                                            )},"click:clear":function($event){return _vm.handleAutoCompleteChange(
                                                'retrievePayload',
                                                'customer_id',
                                                'id',
                                                $event
                                            )},"intersect":function($event){return _vm.retrieveCustomers(
                                                'name',
                                                _vm.retrieveCustomerOps
                                            )}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('CustomButton',{attrs:{"block":_vm.$vuetify.breakpoint.xs
                                                ? true
                                                : false,"btnColour":"primary","btnIsLoading":_vm.retrieveOps.isLoading,"btnLabel":"Search","tooltipMsg":"Create Report","type":"submit"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('CustomSelectField',{attrs:{"id":"exclusions","inputMultiple":"","inputPlaceholder":"Exclusion Fields","optionItems":_vm.exportedAttributes,"optionText":"name","optionValue":"id"},on:{"change":function($event){return _vm.handleSelectChange(
                                                $event,
                                                'exclusions'
                                            )}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('CustomButton',{attrs:{"block":_vm.$vuetify.breakpoint.xs
                                                ? true
                                                : false,"btnIsLoading":_vm.downloadOps.isLoading,"btnLabel":"Download","tooltipMsg":"Download Report"},on:{"click":function($event){return _vm.downloadReport(
                                                _vm.table.options,
                                                _vm.retrievePayload.customer_id,
                                                _vm.range_start,
                                                _vm.range_end,
                                                _vm.exclusions
                                            )}}})],1)],1)],1)]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"footer-props":_vm.table.footers,"headers":_vm.table.headers,"items":_vm.items,"loading":_vm.retrieveOps.isLoading,"loading-text":"Loading...","no-data-text":"No data so far.","no-results-text":"No matches found.","options":_vm.table.options,"search":_vm.table.search,"server-items-length":_vm.retrieveOps.serverItemsLength,"show-select":""},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)},"update:items-per-page":function($event){return _vm.retrieveReport(
                                _vm.table.options,
                                _vm.retrievePayload.customer_id,
                                _vm.range_start,
                                _vm.range_end
                            )},"update:page":function($event){return _vm.retrieveReport(
                                _vm.table.options,
                                _vm.retrievePayload.customer_id,
                                _vm.range_start,
                                _vm.range_end
                            )},"update:sort-by":function($event){return _vm.retrieveReport(
                                _vm.table.options,
                                _vm.retrievePayload.customer_id,
                                _vm.range_start,
                                _vm.range_end
                            )},"update:sort-desc":function($event){return _vm.retrieveReport(
                                _vm.table.options,
                                _vm.retrievePayload.customer_id,
                                _vm.range_start,
                                _vm.range_end
                            )}},scopedSlots:_vm._u([{key:"item.range_start",fn:function(ref){
                            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.toLocalDateTime(item.range_start)))])]}},{key:"item.range_end",fn:function(ref){
                            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.toLocalDateTime(item.range_end)))])]}}]),model:{value:(_vm.table.selectedItems),callback:function ($$v) {_vm.$set(_vm.table, "selectedItems", $$v)},expression:"table.selectedItems"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }